import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loader from './Loader';
import PostPayment from './Pages/PostPayment';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/status">
          <PostPayment />
        </Route>
        <Route path="/:id">
          <Loader></Loader>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
