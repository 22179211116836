import './App.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
export default function Loader() {
  const [isLoading, setLoading] = useState(true);
  const [isFailed, setFailed] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    loadOrderLink();
  }, []);

  const loadOrderLink = async () => {
    try {
      const request = await axios.post('https://dusk.masliukov.com/v4/activate-order/', { id: id });
      console.log({ data: request.data });
      const { url } = request.data;
      console.log(request.data);
      if (url !== undefined) {
        window.location = url;
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setFailed(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Оплата</title>
        <meta name="referrer" content="origin" />
      </Helmet>
      <div className="container">
        {isLoading && (
          <>
            <div className="text">Сейчас вы будете перенаправлены</div>
            <span className="sub-text">{id}</span>
            <div className="lds-dual-ring"></div>{' '}
          </>
        )}
        {isFailed && (
          <>
            <div className="text">Упс, что-то сломалось</div>
            <span className="sub-text">{id}</span>
          </>
        )}
      </div>
    </>
  );
}
